.white-edge-eliminator {
  position: fixed;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #1A1917;
}

.moving-dots-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #1A1917;
  filter: blur(2px);
}

.dot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #66636e;
  border-radius: 50%;
}