.home-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width:50%;
    text-align: right;
    transform: translateY(-10%);
    
  }
  
  .name {
    margin-bottom: 0px;
    font-family: 'Biotif Bold', sans-serif;
  }
  
  .name h1 {
    font-size: 108px; 
    color: #ffffff;
    margin-bottom: 15px;
  }
  
  .social-icons a {
    margin: 0 8px; 
  }
  
  .social-icons img {
    width: 35px; 
    height: auto;
  }
  
  @media screen and (max-width: 790px) {
    .home-page {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  
    .name h1 {
      font-size: 72px; 
    }
  }