.navbar {
    position: fixed;
    text-align: center;
    flex: 1;
    order: 2;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    z-index: 999;
  }

  .navbar-header {
    display: flex;
    align-items: center;
    color: white;
    font-size: 12px;
    min-height: 59px;
    width: 100%;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    z-index: 3;
    margin-top: 13px;
    margin-bottom: 13px;
  }
  
  .navbar-tabs {
    display: inline-flex;
    justify-content: space-between; 
    list-style-type: none;
    position: relative;
    margin: 0;
    padding: 0;
    max-width: 100%;
  }

  .list-item {
    text-decoration: none;
  }
  
  .tab-item {
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .text {
    padding: 15px 40px; 
    font-size: 24px; 
    color: #a6a5a8; 
    z-index: 1;
    /*text-transform: uppercase; /* all uppercase*/ 
  }
  
  .pill {
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%; 
    height: 10%; 
    background-color: transparent;
    border-radius: 20px;
    transition: background-color 0.3s ease;
  }
  
  .tab-item:hover .pill {
    background-color: #575461; 
  }
  
  .tab-item:hover .text {
    color: #cecdd4;
  }

  /* Active - the one that's selected */
  .tab-item.active .text {
    color: #e3e2e9;
  }
  
  .navbar-tabs a {
    text-decoration: none;
    color: inherit;
  }

  @media screen and (max-width: 730px) {
    .navbar {
      overflow-x: scroll; 
      overflow-y: hidden;
      width: 100%;
      scrollbar-width: thin; 
      scrollbar-color: #888 transparent; 
    }
  
    .navbar::-webkit-scrollbar {
      width: 8px; 
    }
  
    .navbar::-webkit-scrollbar-thumb {
      background-color: #888; 
      border-radius: 10px; 
    }

    .pill {
      display:none;
    }
  }