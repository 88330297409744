.about-container {
  display: flex;
}

.about-text a {
  color: #ffffff;
}

.about-text h1 {
  font-size: 2.5em;
  margin-top: 20px;
}

@media screen and (min-width: 971px) {
  .about-container {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  .about-image img {
    position: absolute;
    top: 70%; 
    /*right: 50; /* Right align - useless? */
    /*left: -5%; /*keeps image centered*/
    transform: translateY(-50%); 
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: cover;
  }

  .about-image {
    width: 45%; /* keep at 45% to keep ratio */
    max-width: 110%; 
    height: 100%;
    overflow: hidden; 
    position: relative; 
  }

  .about-text {
    font-size: 1.7em;
  }
}

@media screen and (min-width: 1530px) {
  .about-image img {
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -23%); 
    width: 100%;
  }

  .about-text {
    font-size: 1.7em; 
  }
}

@media screen and (min-width: 971px) and (max-width: 1529px) {
  .about-text {
    font-size: 1.5em; 
  }
}

@media screen and (min-width: 540px) and (max-width: 970px) {
  .about-text {
    font-size: 1.4em; 
  }
}

.about-text {
  width: 45%; /* keep at 45% to keep ratio */
  padding: 20px;
  color: #ffffff;
  text-align: center; 
  border-radius: 15px; 
  background-color: rgba(73, 71, 82, 0.7);
}

@media screen and (max-width: 970px) {
  .about-container {
    flex-direction: column; 
    align-items: center;
    justify-content: center;
  }

  .about-text {
    width: 80%; 
    margin-top: 80px;
    margin-bottom: 20px;
  }

  .about-image {
    width: 80%; 
  }

  .about-image img {
    width: 80%; 
    height: auto;
    transform: translateX(9%);
  }

  
}

@media (max-width: 540px) {
  .about-text {
    font-size: 1.2em; 
  }
}